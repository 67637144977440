import React from "react";
import { Card, Modal, Button } from "react-bootstrap";

function cookiesModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <strong>What are cookies?</strong>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card.Text>
          Cookies perform essential functions on the internet, but can also
          collect data you might not want shared.
        </Card.Text>
        <Card.Text>
          A cookie is a small piece of data stored on your computer while
          browsing a website. Cookies remember information (such as items added
          in the shopping cart in an online store) or record browsing activity
          (such as logging in, or which pages were visited in the past). They
          can also be used to remember information entered into form fields,
          such as names, addresses, passwords, and payment card numbers.
        </Card.Text>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default cookiesModal;
