import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import Footer from "../shared/components/Footer";
import ImportScript from "../shared/components/ImportScript";

function PrivacyIncidentCenter() {
  return (
    <>
      <SrrHeader
        heading="Privacy Incident Center"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="PRIVACY INCIDENT CENTER"
      />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_COE_PRIVACY_INCIDENT_CENTER}
          pagId={process.env.REACT_APP_COE_PRIVACY_INCIDENT_CENTER_PGID}
        />
      </section>
      <Footer />
    </>
  );
}

export default PrivacyIncidentCenter;
