import { useEffect } from "react";
const ImportScript = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = props.src;
    script.dataset.pageId = props.pageId;
    script.id = "wirewheel-ppp";
    script.dataset.jsonSrc = `${process.env.REACT_APP_CURRENT_ENVIRONMENT_URI}`;
    script.async = true;
    script.crossOrigin = "anonymous";
    script.onload = function () {
      console.log("script loaded");
      var DOMContentLoaded_event = document.createEvent("Event");
      DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
      window.document.dispatchEvent(DOMContentLoaded_event);
    };
    document.getElementById("wirewheel").appendChild(script);
  }, [props.src, props.pageId]);

  return <div id="wirewheel"></div>;
};
export default ImportScript;
