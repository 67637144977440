import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import Footer from "../shared/components/Footer";
import ImportScript from "../shared/components/ImportScript";

function RecordOfProcessing() {
  return (
    <>
      <SrrHeader
        heading="Record Of Processing"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="PRIVACY INCIDENT CENTER"
      />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_COE_RECORD_OF_PROCESSING}
          pagId={process.env.REACT_APP_COE_RECORD_OF_PROCESSING_PGID}
        />
      </section>
      <Footer />
    </>
  );
}

export default RecordOfProcessing;
