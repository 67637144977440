import React from "react";

function SrrHeader(props) {
  const url =
    props.breadcrumb_path1 === "WIREWHEEL TRUST CENTER"
      ? "/"
      : "/centerofexcellence";

  return (
    <>
      <div className="heading">
        <div className="container">
          <h1>{props.heading}</h1>
        </div>
      </div>
      <div className="sub-heading bg-gray margin-bottom-30" id="request-form">
        <div className="container">
          <ul className="wirewheel-breadcrumb">
            <li>
              <a className="sub-heading--wirewheel-anchor" href={url}>
                {props.breadcrumb_path1}
              </a>
            </li>
            <li>
              <p className="sub-heading--wirewheel-anchor">
                {props.breadcrumb_path2}
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SrrHeader;
