import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Form } from "react-bootstrap";
import Recaptcha from "./ReCaptcha";
import wwLogo from "../shared/Images/wwLogo.svg";
import { useForm } from "react-hook-form";
import { textValidation, emailValidation } from "./Validation";
import getPayload from "./getPayload";
import { getService } from "../shared/restApi";

function RequestForm(props) {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [sendRequest, setSendRequest] = useState(false);
  const {
    configValues,
    enableLoader,
    getAcknowledgement,
    getSubmitRequestId,
    cookieValue,
  } = props;

  const handleReCaptchaClick = (value) => {
    setDisableSubmit(!value);
  };

  const { register, handleSubmit, formState, errors } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data) => {
    console.log("onSubmit", data);
    setSendRequest(data);
  };

  useEffect(() => {
    if (sendRequest) {
      console.log("sendRequest");
      // activate the loader
      enableLoader(true);
      console.log("inputsData:", sendRequest);
      console.log("cookieValue", cookieValue);
      // document.getElementById('usprivacy').value = cookieValue;
      // document.getElementById('usprivacy').dispatchEvent(new Event('input', {}));

      // payload
      const inputsForPayLoad = {
        request: "optOut",
        inputsData: sendRequest,
        configValues,
        cookieValue,
      };

      console.log("inputsForPayload:", inputsForPayLoad);
      const payload = getPayload(inputsForPayLoad);

      // API request

      const url = "https://acmebank.wirewheel.io/api/public/v1/self-service";

      const submitOptions = {
        method: `POST`,
        url,
        data: payload,
      };

      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            getSubmitRequestId(response.data.rootId);
          }
        })
        .then(() => {
          // deactivate the loader and enable the Acknowledgement
          enableLoader(false);
          getAcknowledgement(true);
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
    return () => {
      setSendRequest(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sendRequest]);

  return (
    <div className="main-content">
      <h1 className="mt-5 mb-3">
        <strong>Make a Request</strong>
      </h1>
      <h6 className="main-title">
        We are committed to the responsible collection and use of your data.
      </h6>
      <h6 className="main-title">
        If you want to opt out of the sale of personal information, fill out the
        form below.
      </h6>

      <Card className="my-5 mx-auto form shadow">
        <Card.Body>
          <div className="form-title mb-3">
            <Card.Text>
              <strong>
                Please provide the following information to start your request:
              </strong>
            </Card.Text>
            <Card.Text>
              <span className="text-danger">*</span> Required fields
            </Card.Text>
          </div>
          <Form className="form-inputs" onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3">
              <Row>
                <Col>
                  <Form.Label className="fw-bold">
                    First Name{}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="firstName"
                    placeholder="First Name *"
                    className={!errors.firstName ? "mb-2" : "error-border"}
                    ref={register(textValidation)}
                  />
                  {errors.firstName && (
                    <small className="text-danger">
                      {" "}
                      {errors.firstName.message}{" "}
                    </small>
                  )}
                </Col>

                <Col>
                  <Form.Label className="fw-bold">
                    Last Name{}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lastName"
                    placeholder="Last Name *"
                    className={!errors.lastName ? "mb-2" : "error-border"}
                    ref={register(textValidation)}
                  />
                  {errors.lastName && (
                    <small className="text-danger">
                      {" "}
                      {errors.lastName.message}{" "}
                    </small>
                  )}
                </Col>
              </Row>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Row>
                <Form.Label className="fw-bold">
                  Email{}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter your Email Address *"
                  className={!errors.email ? "mb-2" : "error-border mb-2"}
                  ref={register(emailValidation)}
                />
                {errors.email && (
                  <small className="text-danger">{errors.email.message}</small>
                )}
              </Form.Row>

              <Card.Text>
                We'll use this email address to reply to your request
              </Card.Text>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Row>
                <Form.Label className="fw-bold">
                  Country{}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  as="select"
                  defaultValue="United States"
                  name="country"
                >
                  <option>United States</option>
                  <option>...</option>
                </Form.Control>
              </Form.Row>
            </Form.Group>

            <Form.Group className="mb-3">
              <Card.Text className="fw-bold">
                Are you a current member? <span className="text-danger">*</span>
              </Card.Text>
              <Form.Check
                type="radio"
                label="Yes"
                value="Yes"
                name="member"
                id="member"
                ref={register({ required: true })}
              />
              <Form.Check
                type="radio"
                label="No"
                value="No"
                name="member"
                id="nonMember"
                ref={register({ required: true })}
              />
            </Form.Group>

            <div className="d-flex justify-content-center mt-4">
              <Recaptcha reCaptchaClick={handleReCaptchaClick} />
            </div>

            <Form.Group>
              <Row className="d-flex justify-content-center mt-5">
                <Col sm={12} md={10} lg={10} className="text-center">
                  <Button
                    type="submit"
                    variant="dark"
                    size="lg"
                    style={{
                      width: "inherit",
                      backgroundColor: "#046a38",
                      borderColor: "#046a38",
                    }}
                    disabled={!formState.isValid || disableSubmit}
                  >
                    Submit Request
                  </Button>
                </Col>
              </Row>
            </Form.Group>
          </Form>
        </Card.Body>
      </Card>

      <div className="footer-content text-center mb-5">
        <strong>
          <a href="cookiepolicy" className="footer-links">
            Cookie policy
          </a>{" "}
          -{" "}
          <a href="privacypolicy" className="footer-links">
            Privacy Policy
          </a>
        </strong>
        <p className="mt-3" style={{ color: "#3C444F" }}>
          Powered by <img alt="wirewheel logo" src={wwLogo} className="ml-2" />
        </p>
      </div>
    </div>
  );
}

export default RequestForm;
