import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import CookiesModal from "./components/cookiesModal";
import CookieBar from "./components/CookieBar";
import RequestForm from "./components/RequestForm";
import { getService } from "./shared/restApi";
import Acknowledgement from "./components/Acknowledgement";
import "./shared/styles/custom.scss";

function OptOut() {
  const [modalShow, setModalShow] = React.useState(false);
  const [configValues, setConfigValues] = useState({});
  const [configValuesBtn, setConfigValuesBtn] = useState({});
  const [loader, setLoader] = useState(false);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [id, setId] = useState("");
  const [cookieValue, setCookieValue] = useState("");
  const [ipAddress, setIpAddress] = useState("");

  const cookiesModalHandler = (e) => {
    e.preventDefault();
    setModalShow(true);
  };

  const handleCookieValue = (val) => {
    setCookieValue(val);
  };

  // handling the loader activation here
  const handleLoader = (value) => {
    setLoader(value);
  };

  // handling the acknowledgement activation here
  const handleAcknowledgement = (acknowledge) => {
    setShowAcknowledgement(acknowledge);
  };

  // handling the submit request root Id here
  const handleSubmitRequestId = (value) => {
    setId(value);
  };

  // useEffect to get the configuration values
  useEffect(() => {
    console.log("getting Form schema");
    const url =
      "https://wirewheel-p3-public-storage-acmebank.s3.us-east-1.amazonaws.com/6066fae84bf0a3003c209b81/p3-data.json";
    let configOptions = {
      method: "GET",
      url,
    };
    const configData = getService(configOptions);

    configData
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});
          setConfigValues({ actions, settings: settingsId, _id: settingsId });
        }
      })
      .catch((err) => {
        throw err;
      });

    console.log("getting Optout Button schema");
    const urlBtn =
      "https://wirewheel-p3-public-storage-acmebank.s3.us-east-1.amazonaws.com/6051de1e11de430022a2f6df/p3-data.json";
    let configOptionsBtn = {
      method: "GET",
      url: urlBtn,
    };
    const configDataBtn = getService(configOptionsBtn);
    configDataBtn
      .then((response) => {
        if (response.status === 200 && response.data) {
          const { requests, settingsId } = response.data.srrConfig;
          const actions = requests.reduce((actionsMap, request) => {
            actionsMap[request.requestType] = request;
            return actionsMap;
          }, {});

          setConfigValuesBtn({
            actions,
            settings: settingsId,
            _id: settingsId,
          });
        }
      })
      .catch((err) => {
        throw err;
      });
  }, []);

  useEffect(() => {
    let ipConfigOptions = {
      method: "GET",
      url: "https://api.ipify.org",
    };

    const ipConfigData = getService(ipConfigOptions);
    ipConfigData.then((response) => {
      if (response.status === 200 && response.data) {
        setIpAddress(response.data);
      }
    });
  }, []);

  return (
    <div className="App">
      {modalShow ? (
        <CookiesModal show={modalShow} onHide={() => setModalShow(false)} />
      ) : (
        ""
      )}
      <div className="banner index-banner"></div>
      <Container className="pt-2">
        <CookieBar
          cookieHandler={cookiesModalHandler}
          getCookieValue={handleCookieValue}
          ipAddress={ipAddress}
          configValues={configValuesBtn}
        />
        {loader ? (
          <div className="">
            <Spinner animation="grow" variant="primary" className="spinner" />
          </div>
        ) : showAcknowledgement ? (
          <Acknowledgement id={id} />
        ) : (
          <RequestForm
            configValues={configValues}
            enableLoader={handleLoader}
            getAcknowledgement={handleAcknowledgement}
            getSubmitRequestId={handleSubmitRequestId}
            cookieValue={cookieValue}
          />
        )}
      </Container>
    </div>
  );
}

export default OptOut;
