import React, { useEffect } from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCookies } from "react-cookie";
import { getService } from "../shared/restApi";
import getPayload from "./getPayload";

function CookieBar(props) {
  // const [optOutClick, setOptOutClick] = useState(false);
  const { getCookieValue, ipAddress, configValues } = props;
  const [cookies, setCookie] = useCookies(["usprivacy"]);
  const [ipCookie, setipCookie] = useCookies(["ipaddress"]);

  const handleOptOut = () => {
    // setOptOutClick(!optOutClick);
    if (cookies["usprivacy"]) {
      setCookie("usprivacy", "", { path: "/" });
    } else {
      setCookie("usprivacy", "1-Y-", { path: "/" });
    }

    if (ipCookie["ipaddress"]) {
      setipCookie("ipaddress", "", { path: "/" });
    } else {
      setipCookie("ipaddress", ipAddress, { path: "/" });
      // payload
      const inputsForPayLoad = {
        request: "optOut",
        inputsData: "",
        configValues,
        ipAddress,
      };

      const payload = getPayload(inputsForPayLoad);
      // API request

      const url = "https://acmebank.wirewheel.io/api/public/v1/self-service";

      const submitOptions = {
        method: `POST`,
        url,
        data: payload,
      };
      const submitResponse = getService(submitOptions);
      submitResponse
        .then((response) => {
          if (response.status === 201 && response.data) {
            // get the root id after successful request submit
            console.log("OptOut Request is Submitted");
          }
        })
        .catch((err) => {
          console.error(err);
          // TODO: show error to user
        });
    }
  };

  useEffect(() => {
    if (cookies["usprivacy"]) {
      getCookieValue(cookies["usprivacy"]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cookies]);

  return (
    <div>
      <Card className="mt-5 shadow-sm">
        <Card.Body>
          <Row className="">
            <Col sm={12} lg={10} className="text-start">
              <h5>
                <b>
                  Opt Out of the Sale of Personal Information Collected on This
                  Browser
                </b>
              </h5>
              <p>
                If you'd like to register an opt out of the sharing of your data
                to third parties for the purposes of delivering ads tailored to
                your interests, select the opt-out box. Each time you clear your
                cookies or use another browser to access our site, you will need
                to select this box.{" "}
              </p>
              <p>
                <span
                  onClick={props.cookieHandler}
                  style={{ cursor: "pointer" }}
                >
                  <strong>
                    <u>Learn more about cookies?</u>
                  </strong>
                </span>
              </p>
            </Col>
            <Col
              sm={12}
              lg={2}
              className="d-flex justify-content-center align-items-center"
            >
              {cookies["usprivacy"] && ipCookie["ipaddress"] ? (
                <Button variant="success" size="lg" onClick={handleOptOut}>
                  Opted Out{" "}
                  <span>
                    <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                  </span>
                </Button>
              ) : (
                <Button
                  style={{ backgroundColor: "#046a38", borderColor: "#046a38" }}
                  size="lg"
                  onClick={handleOptOut}
                >
                  Opt Out
                </Button>
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
}

export default CookieBar;
