import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import TrustCenter from "./components/TrustCenter";
import SubmitRequest from "./components/SubmitRequest";
import OptOut from "./components/OptOut";
import Header from "./shared/components/Header";
import CenterOfExcellence from "./components/CenterOfExcellence";
import RecordOfProcessing from "./components/RecordOfProcessing";
import PrivacyByDesign from "./components/PrivacyByDesign";
import PrivacyImpactAssessment from "./components/PrivacyImpactAssessment";
import ThirdPartyAssessment from "./components/ThirdPartyAssessment";
import PrivacyIncidentCenter from "./components/PrivacyIncidentCenter";
import "./App.scss";

function App() {
  return (
    <>
      <Header />
      <Router>
        <Switch>
          <Route path="/" exact component={TrustCenter} />
          <Route path="/submit-request" component={SubmitRequest} />
          <Route path="/optout" component={OptOut} />
          <Route path="/centerofexcellence" component={CenterOfExcellence} />
          <Route path="/recordofprocessing" component={RecordOfProcessing} />
          <Route path="/privacybydesign" component={PrivacyByDesign} />
          <Route
            path="/privacyimpactassessment"
            component={PrivacyImpactAssessment}
          />
          <Route
            path="/thirdpartyassessment"
            component={ThirdPartyAssessment}
          />
          <Route
            path="/privacyincidentcenter"
            component={PrivacyIncidentCenter}
          />
        </Switch>
      </Router>
    </>
  );
}

export default App;
