// function to create the paylod with necessary fields based on request
export default function getPayload({
  request,
  inputsData,
  configValues,
  cookieValue,
  ipAddress,
}) {
  for (let key in configValues.actions[request]) {
    if (key === "fields") {
      const dataFields = configValues.actions[request][key]
        .map((field) => {
          switch (field.label) {
            case "Are you a current member?":
              return {
                value: inputsData["member"],
                label: field.label,
                _id: field._id,
              };
            case "First Name":
              return {
                value: inputsData["firstName"],
                label: field.label,
                _id: field._id,
              };
            case "Last Name":
              return {
                value: inputsData["lastName"],
                label: field.label,
                _id: field._id,
              };
            case "Country":
              return {
                value: inputsData["country"] || "United States",
                label: field.label,
                _id: field._id,
              };
            case "usprivacy":
              return {
                value: cookieValue || "",
                label: field.label,
                _id: field._id,
              };
            case "IP Address":
              return {
                value: ipAddress || "",
                label: field.label,
                _id: field._id,
              };
            case "Email":
              return {
                value: inputsData["email"],
                label: field.label,
                _id: field._id,
              };
            default:
              console.warn(`unknown field ${field.label}`);
          }
          return null;
        })
        .filter((field) => field);

      const primaryEmail = inputsData["email"] ? inputsData["email"] : "";

      const dataInputs = {
        data: {
          providedFields: dataFields,
          primaryEmail,
          providedRequests: [request],
        },
        meta: {
          id: `${configValues.settings}`,
          pageDataId: `${configValues._id}`,
          type: "dsar",
        },
      };
      return dataInputs;
    }
  }
}
