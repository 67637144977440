import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Footer from "../shared/components/Footer";
import privacy_policy from "../shared/images/privacy_policy.png";
import metrics from "../shared/images/metrics.png";
import write from "../shared/images/write.png";

function CenterOfExcellence() {
  return (
    <>
      <div className="banner centerofexcellence-banner"></div>
      <div className="heading padding-tb-20">
        <Container>
          <h1>Center Of Excellence</h1>
          {/* <h2>Understand how we honor your data privacy rights.</h2> */}
        </Container>
      </div>
      <Container className="my-5">
        <Row className="ds-flex justify-content-center">
          <Col xs={12} md={4}>
            <a className="card-tc" href="/recordofprocessing">
              <img src={privacy_policy} width="50px" alt="recordofprocessing" />
              <p className="margin-top--10 fw--400">Record of Processing</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a className="card-tc" href="/privacybydesign">
              <img src={metrics} width="50px" alt="privacybydesign" />
              <p className="margin-top--10 fw--400">Privacy By Design</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a className="card-tc" href="/privacyimpactassessment">
              <img
                src={privacy_policy}
                width="50px"
                alt="privacyimpactassessment"
              />
              <p className="margin-top--10 fw--400">
                Privacy Impact Assessment
              </p>
            </a>
          </Col>
        </Row>
        <Row className="ds-flex justify-content-center">
          <Col xs={12} md={4}>
            <a
              className="card-tc"
              href="/thirdpartyassessment"
              target="_blank"
              rel="noreferrer"
            >
              <img src={metrics} width="50px" alt="thirdpartyassessment" />
              <p className="margin-top--10 fw--400">Third Party Assessment</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a className="card-tc" href="/privacyincidentcenter">
              <img src={write} width="50px" alt="privacyincidentcenter" />
              <p className="margin-top--10 fw--400">Privacy Incident Center</p>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default CenterOfExcellence;
