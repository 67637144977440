import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import Footer from "../shared/components/Footer";
import privacy_policy from "../shared/images/privacy_policy.png";
import metrics from "../shared/images/metrics.png";
import write from "../shared/images/write.png";

function TrustCenter() {
  return (
    <>
      <div className="banner index-banner"></div>
      <div className="heading padding-tb-20">
        <Container>
          <h1>Wirewheel Trust Center</h1>
          <h2>Understand how we honor your data privacy rights.</h2>
        </Container>
      </div>
      <Container className="my-5">
        <Row className="ds-flex justify-content-center">
          <Col xs={12} md={4}>
            <a
              className="card-tc"
              href="https://wirewheel.io/wirewheel-privacy-notice/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={privacy_policy} width="50px" alt="privacy_policy" />
              <p className="margin-top--10 fw--400">Privacy Policy</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a className="card-tc" href="/submit-request">
              <img src={metrics} width="50px" alt="metrics" />
              <p className="margin-top--10 fw--400">DSAR</p>
            </a>
          </Col>
          <Col xs={12} md={4}>
            <a className="card-tc" href="/optout">
              <img src={write} width="50px" alt="optout" />
              <p className="margin-top--10 fw--400">Opt Out</p>
            </a>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default TrustCenter;
