import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import Footer from "../shared/components/Footer";
import ImportScript from "../shared/components/ImportScript";

function PrivacyImpactAssessment() {
  return (
    <>
      <SrrHeader
        heading="Privacy Impact Assessment"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="PRIVACY IMPACT ASSESSMENT"
      />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_COE_PRIVACY_IMPACT_ASSESSMENT}
          pagId={process.env.REACT_APP_COE_PRIVACY_IMPACT_ASSESSMENT_PGID}
        />
      </section>
      <Footer />
    </>
  );
}

export default PrivacyImpactAssessment;
