import React from "react";
import SrrHeader from "../shared/components/SrrHeader";
import Footer from "../shared/components/Footer";
import ImportScript from "../shared/components/ImportScript";

function PrivacyByDesign() {
  return (
    <>
      <SrrHeader
        heading="Privacy By Design"
        breadcrumb_path1="CENTER OF EXCELLENCE"
        breadcrumb_path2="PRIVACY BY DESIGN"
      />

      <section className="form-section">
        <ImportScript
          src={process.env.REACT_APP_COE_PRIVACY_BY_DESIGN}
          pagId={process.env.REACT_APP_COE_PRIVACY_BY_DESIGN_PGID}
        />
      </section>
      <Footer />
    </>
  );
}

export default PrivacyByDesign;
