import React, { useState } from "react";
import { Nav, Navbar, NavDropdown, Image, Row, Col } from "react-bootstrap";
import Logo from "../images/logo.png";
import { Container } from "react-bootstrap";

function Header() {
  const [showHome, setShowHome] = useState(false);
  const [showProducts, setShowProducts] = useState(false);
  const [showTC, setShowTC] = useState(false);
  const [showCOE, setShowCOE] = useState(false);
  //   const showDropdown = (e) => {

  //   };
  //   const hideDropdown = (e) => {

  //   };

  return (
    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand href="https://www.wirewheel.io" target="_blank">
          <Image
            src={Logo}
            alt="Wirewheel logo"
            className="p-2"
            width="140px"
          />
        </Navbar.Brand>
        <div className="flexarea"></div>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Row className="ms-auto me-4">
            <Col lg={3}>
              <Nav>
                <NavDropdown
                  title="Home"
                  show={showHome}
                  onMouseEnter={() => setShowHome(true)}
                  onMouseLeave={() => setShowHome(false)}
                  id="home"
                  className="fw-bold"
                >
                  <NavDropdown.Item
                    href="https://wirewheel.io/company/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Welcome
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://wirewheel.io/contact-us/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Contact
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://wirewheel.io/blog/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Blog
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Col>
            <Col lg={3}>
              <Nav className="">
                <NavDropdown
                  title="Products"
                  show={showProducts}
                  onMouseEnter={() => setShowProducts(true)}
                  onMouseLeave={() => setShowProducts(false)}
                  id="products"
                  className="fw-bold"
                >
                  <NavDropdown.Item
                    href="https://wirewheel.io/privacy-operations-manager/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Operations Manager
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://wirewheel.io/consent-management-platform/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Consent Management Platform Solution
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://wirewheel.io/automation-integrations/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Automation & Integrations
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://wirewheel.io/wirewheel-privacy-assessment-management/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Assessment
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://wirewheel.io/wirewheel-data-inventory-management/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Data Inventory
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Col>
            <Col lg={3}>
              <Nav className="">
                <NavDropdown
                  onClick={() => {
                    window.location.href = "/";
                  }}
                  title="Trust Center"
                  show={showTC}
                  onMouseEnter={() => setShowTC(!showTC)}
                  onMouseLeave={() => setShowTC(false)}
                  id="trustcenter"
                  className="fw-bold"
                >
                  <NavDropdown.Item
                    href="https://wirewheel.io/wirewheel-privacy-notice/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/submit-request">
                    DSAR
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/optout">Opt Out</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Col>
            <Col lg={3}>
              <Nav>
                <NavDropdown
                  title="Center of Excellence"
                  onClick={() => {
                    window.location.href = "/centerofexcellence";
                  }}
                  show={showCOE}
                  onMouseEnter={() => setShowCOE(!showCOE)}
                  onMouseLeave={() => setShowCOE(false)}
                  id="centerofexcellence"
                  className="fw-bold"
                >
                  <NavDropdown.Item href="/recordofprocessing">
                    Record of Processing
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/privacybydesign">
                    Privacy by Design
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/privacyimpactassessment">
                    Privacy Impact Assessment
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/thirdpartyassessment">
                    Third Party Assessment
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/privacyincidentcenter">
                    Privacy Incident Center
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Col>
          </Row>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
